import Instrument from 'habitual-analytics/models/instrument';
import moment from 'moment';

export const ParseTradingSymbol = (tradingSymbolObject) => {
  const { expiry } = tradingSymbolObject;
  if (tradingSymbolObject['exchange'] !== 'NSE') {
    tradingSymbolObject['expiryDate'] = moment({
      year: expiry?.year,
      month: expiry?.month - 1,
      day: expiry?.date
    }).format('YYYY-MM-DD');
  }
  tradingSymbolObject['symbol'] = _.get(tradingSymbolObject, 'instrument', '');
  return new Instrument(tradingSymbolObject);

};