import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';
import { getApiCredentials } from './utility';
import { Formatter } from './formatter/index';
import { Parser } from './parser/index';
import { TOASTIFY_TYPES, reactToastify } from 'globals/utils/reactToastify';
import { brokerSpecificTableHeaders } from './utility';
import { isArray } from 'lodash';

const apiCalls = {
  orderBook: 'orderBook',
  positionBook: 'positionBook',
  tradeBook: 'tradeBook',
  holdingBook: 'holdingBook',
  marginCalculation: 'marginCalculation',
  placeOrders: 'placeOrders',
  cancelOrders: 'cancelOrders',
  modifyOrders: 'modifyOrders',
  postBackOrder: 'orderBookFromPostback',
};

export const OrderFacade = (broker) => {
  const BASE_URL = REST_URLS().ORDER_FACADE;

  const {
    orderTableHeaders,
    tradeTableHeaders,
    positionTableHeaders,
    holdingTableHeaders } = brokerSpecificTableHeaders();

  const parseBookResponse = (response, headers, rawBook, resultKey, parser) => {
    if (!response?.success) {
      return {
        success: false,
        message: response.error.reason || response.error.message,
        headers,
        [resultKey]: []
      };
    }
    const parsedBook = isArray(rawBook)
      ? _.map(rawBook, parser)
      : [parser(rawBook)];

    const sortKey = resultKey === 'positionBookDetails' ? 'extraDetails.isOpenPosition' : 'time';
    const sortedBooks = _.orderBy(_.compact(parsedBook), [sortKey], ['desc']);
    return {
      success: true,
      headers,
      [resultKey]: sortedBooks,
    };
  };

  //api call
  const makeApiCall = async (broker, method, orderDetails) => {

    const requestBody = {
      brokerName: broker?.toUpperCase(),
      apiMethodName: method,
      apiCredentials: getApiCredentials(broker),
      orders: orderDetails,
    };
    const parameters = {
      method: 'POST',
      body: JSON.stringify(requestBody),
    };
    const response = await wrappedFetch(`${BASE_URL}`, parameters);
    return response.json();
  };

  return {
    isValidAccessToken: async () => {
      try {
        const result = await makeApiCall(broker, apiCalls.orderBook, []);
        return _.get(result, 'success', false);
      } catch (error) {
        console.log('validationError', error);
        return false;
      }
    },
    placeOrders: async (orders) => {
      const formattedOrders = _.map(orders, Formatter().placeOrder);
      try {
        const result = await makeApiCall(broker, apiCalls.placeOrders, formattedOrders);
        const response = Parser().parseTransactionResponse(result);

        // Stop Gap sending reuslt in old format.
        return _.map(response, (responseItem) => (responseItem.success)
          ? responseItem.orderId
          : responseItem
        );
      } catch (error) {
        reactToastify(TOASTIFY_TYPES.ERROR, 'Place Orders: ' + error.message);
        throw error;
      }
    },
    cancelOrder: async (order) => {
      try {
        const result = await makeApiCall(broker, apiCalls.cancelOrders, Formatter().cancelOrder(order));
        return {
          ...(Parser().parseTransactionResponse(result)[0]),
          orderId: order.orderNo
        };
      } catch (error) {
        console.log('Error :', error);
        throw error;

      }
    },
    modifyOrder: async (order) => {
      try {
        const result = await makeApiCall(broker, apiCalls.modifyOrders, Formatter().modifyOrder(order[0]));
        const response = Parser().parseTransactionResponse(result);
        return _.map(response, (responseItem) => (responseItem.success)
          ? responseItem.orderId
          : responseItem
        );
      } catch (error) {
        console.error('Error:', error);
        reactToastify(TOASTIFY_TYPES.ERROR, 'Modify Order', error.message);
        throw error;

      }
    },
    orderBook: async () => {
      try {
        const response = await makeApiCall(broker, apiCalls.orderBook, []);
        return parseBookResponse(
          response,
          orderTableHeaders,
          _.get(response, 'orderBook', []),
          'orderBookDetails',
          Parser().parseOrderBook
        );
      } catch (error) {
        console.log('Error fetching order book:', error);
        throw error;
      }
    },

    tradeBook: async () => {
      try {
        const response = await makeApiCall(broker, apiCalls.tradeBook, []);
        return parseBookResponse(
          response,
          tradeTableHeaders,
          _.get(response, 'tradeBook', []),
          'tradeBookDetails',
          Parser().parseTradeBook
        );
      } catch (error) {
        console.error('Error fetching trade book:', error);
        throw error;
      }
    },

    positionBook: async () => {
      try {
        const response = await makeApiCall(broker, apiCalls.positionBook, []);
        return parseBookResponse(
          response,
          positionTableHeaders,
          _.get(response, 'positionBook', []),
          'positionBookDetails',
          Parser().parsePositionBook
        );
      } catch (error) {
        console.error('Error fetching position book:', error);
        throw error;
      }
    },
    holdingBook: async () => {
      try {
        const response = await makeApiCall(broker, apiCalls.holdingBook, []);
        return parseBookResponse(
          response,
          holdingTableHeaders,
          _.get(response, 'holdingBook', []),
          'holdingBookDetails',
          Parser().parseHoldingBook
        );
      } catch (error) {
        console.error('Error fetching holding book:', error);
        throw error;
      }
    },
    marginCalculation: async (orders) => {
      try {
        const response = await makeApiCall(broker, apiCalls.marginCalculation,
          _.map(orders, Formatter().marginCalculation)
        );
        if (!response.success) {
          return {
            success: false,
            message: response.error?.reason || response.error?.message
          };
        }
        return { ..._.get(response, 'margin', {}), success: true };
      } catch (error) {
        console.error('Error fetching marginDetails:', error);
        throw error;
      }
    },

    // Once verify the Code 
    postBackOrder: async (rawData) => {
      try {
        const result = await makeApiCall(broker, apiCalls.postBackOrder, rawData);
        return Parser().parsePostBackOrder(_.get(result, 'orderBook[0]', {}));
      } catch (error) {
        console.log('Error parse postBack', error);
        throw error;
      }
    },
  };

};



