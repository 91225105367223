import { statusConfigs, productCode, productType } from 'habitual-analytics/constants/habitual-configs';



const statusMap = {
  success: statusConfigs.executed.value,
  traded: statusConfigs.executed.value,
  failed: statusConfigs.failed.value,
  cancelled: statusConfigs.cancelled.value,
};

export const sanitizeAndParseOrderStatus = (sts) => {
  const status = sts.toLowerCase();
  return statusMap[status] || statusConfigs.pending.value;
};

export const sanitizeParseOrderType = (orderType) => {
  return productType[orderType] || productType['MARKET'];
};

export const sanitizeProductType = (rawProductCode, tradingSymbolObj) => {
  
  if (tradingSymbolObj?.isEquity() && rawProductCode === productCode['NRML']) return productCode['CNC'];
  return productCode[rawProductCode] || productCode['MIS'];
};
