import { REST_URLS } from 'habitual-analytics/api/resturls/index';
import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  parseCancelOrder,
  parseOrderDetails,
  parsePlaceOrder,
  parseRequiredMarginCalculator,
} from './parser';
import {
  holdingTableHeaders,
  orderTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';
import { updateUser } from 'habitual-analytics/formProvider/UserFormProvider/api';
import { defaultConfigs } from '../order_facade/defaultConfigs';
import { encryptAes, decryptAes } from './encryption';
import _ from 'lodash';
import { sanitizePlaceOrdersBeforeMarginCalculation } from './workmate';

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=axis_direct&method=${method}${queryString}${pathString}`;
};

const getHeaders = () => {
  const { brokerExtras, brokerClientId, brokerClientAccessToken } =
    getDynamicAppConfigs()?.brokerConfigs;
  const { AXIS_DIRECT_AUTHORIZATION_KEY, AXIS_DIRECT_API_CLIENT_ID } =
    getDynamicAppConfigs()?.envs;

  return {
    'X-SubAccountID': brokerClientId,
    'X-API-Client-Id': AXIS_DIRECT_API_CLIENT_ID,
    'X-API-Encryption-Key': brokerExtras?.encryption_key,
    Authorization: AXIS_DIRECT_AUTHORIZATION_KEY,
    'x-authtoken': brokerClientAccessToken,
    'x-service-name': 'test1',
  };
};

const isValidAccessToken = () => {
  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
  };

  return wrappedFetch(getRequestUri('validateUser'), parameters)
    .then((res) => res.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
      return decrypted_data?.status.toLowerCase() === 'ok';
    })
    .catch((e) => {
      return false;
    });
};


const fetchAccessToken = () => {
  const brokerConfigs = getDynamicAppConfigs()?.brokerConfigs;
  const { brokerExtras } = brokerConfigs;
  const payload = JSON.stringify({ refreshToken: brokerExtras?.refresh_token });
  const encrypted_payload = encryptAes(payload);

  const parameters = {
    method: 'POST',
    headers: {
      ..._.omit(getHeaders(), 'authToken'),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return wrappedFetch(getRequestUri('fetchAccessToken'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));

      updateUser({
        broker_access_token: decrypted_data?.data?.token,
        broker_raw_info: {
          auth_token_expiry: decrypted_data?.data?.expireAt,
        },
      }).then(() => {
        initDynamicAppConfigs({
          brokerConfigs: {
            ...brokerConfigs,
            brokerClientAccessToken: decrypted_data?.data?.token,
            brokerExtras: {
              ...brokerExtras,
              auth_token_expiry: decrypted_data?.data?.expireAt,
            },
          },
        });
      });
    })
    .catch((e) => e);
};

const fetchOrderBook = () => {
  const encrypted_payload = encryptAes(
    JSON.stringify({
      segment: 'EQ',
      orderStatus: '-1',
    })
  );
  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(_.get(decrypted_data, 'data', ''), 'order'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = () => {
  const encrypted_payload = encryptAes(
    JSON.stringify({
      segment: 'EQ',
    })
  );

  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return wrappedFetch(getRequestUri('tradeBook'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));

      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(_.get(decrypted_data, 'data', ''), 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const encrypted_payload = encryptAes(
    JSON.stringify({
      segment: 'EQ',
      interop: true,
      type: 'daily',
    })
  );

  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return await wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(decrypted_data?.data, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: defaultConfigs().posititionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const encrypted_payload = encryptAes(
    JSON.stringify({
      segment: 'EQ',
    })
  );

  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(
            _.get(decrypted_data.data, 'holdingDetails', ''),
            'holdings'
          )
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (orderConfigs) => {
  const formattedConfig = await Promise.all(
    orderConfigs.map(async (orderConfig) => {
      const bodyDetails = await parsePlaceOrder(orderConfig);
      const encrypted_payload = encryptAes(JSON.stringify(bodyDetails));

      const parameters = {
        method: 'POST',
        headers: {
          ...getHeaders(),
        },
        credentials: 'omit',
        body: {
          payload: encrypted_payload,
        },
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .then((result) => {
          const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
          return decrypted_data;
        });
    })
  );

  return _.map(formattedConfig, ({ data }) => data?.omsOrderId);
};

const cancelPlaceOrder = ({ orderDetails }) => {
  const bodyDetails = parseCancelOrder(orderDetails);
  const encrypted_payload = encryptAes(JSON.stringify(bodyDetails));

  const parameters = {
    method: 'POST',
    headers: {
      ...getHeaders(),
    },
    credentials: 'omit',
    body: {
      payload: encrypted_payload,
    },
  };

  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters)
    .then((response) => response.json())
    .then((result) => {
      const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
      return decrypted_data?.data?.status === 'SUCCESS'
        ? decrypted_data.data.omsOrderId
        : null;
    });
};

const fetchAvailableMargin = async (parameters) => {
  const availableMarginBody = {
    segment: 'EQ',
  };
  const encrypted_payload = encryptAes(JSON.stringify(availableMarginBody));

  parameters['body'] = {
    payload: encrypted_payload,
  };

  return await wrappedFetch(getRequestUri('availableMargin'), parameters)
    .then((response) => response.json())
    .then((result) => {
      if (result?.['status'] === 'OK') {
        const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
        return decrypted_data;
      }
    });
};

const fetchMarginCalculator = async (orderConfigs) => {
  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'omit',
  };

  const availableMargin = await fetchAvailableMargin(parameters);
  const sanitizedOrderConfigs = sanitizePlaceOrdersBeforeMarginCalculation(orderConfigs);

  const formattedResponse = await Promise.all(
    _.map(sanitizedOrderConfigs, async (orderConfig) => {
      const requiredMargin = await fetchRequiredMargin(orderConfig, parameters);
      return requiredMargin;
    })
  );
  const totalMarginRequired =
    _.chain(formattedResponse)
      .map(({ data }) => parseFloat(data?.['margin']))
      .sum()
      .value() || 0;

  const totalMarginAvailable =
    _.parseInt(availableMargin['data']['currentMarginAvailable']) || 0;
  return { totalMarginRequired, totalMarginAvailable };
};



const fetchRequiredMargin = async (orderConfig, parameters) => {
  const orderDetails = await parseRequiredMarginCalculator(orderConfig);
  const encrypted_payload = encryptAes(JSON.stringify(orderDetails));
  parameters['body'] = {
    payload: encrypted_payload,
  };
  return wrappedFetch(getRequestUri('requiredMargin'), parameters)
    .then((response) => response.json())
    .then((result) => {
      if (result?.['status'] === 'OK') {
        const decrypted_data = JSON.parse(decryptAes(result?.data?.payload));
        return decrypted_data;
      }
    });
};

export {
  isValidAccessToken,
  placeOrder,
  fetchAccessToken,
  fetchOrderBook,
  fetchTradeBook,
  fetchHoldingBook,
  fetchPositionBook,
  cancelPlaceOrder,
  fetchMarginCalculator,
};
