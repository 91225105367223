// Segment configurations
export const segmentConfigs = {
  options: { label: 'OPTIONS', value: 'OPTIONS' },
  futures: { label: 'FUTURES', value: 'FUTURES' },
  equity: { label: 'EQUITY', value: 'EQUITY' },
};
// Expiry types
export const expiryTypes = {
  weekly: { label: 'Weekly', value: 'W', typeIndex: 0 },
  monthly: { label: 'Monthly', value: 'M', typeIndex: 1 },
};
// Transaction types
export const transactionTypes = {
  buy: { value: 'buy', label: 'Buy', minimalLabel: 'B' },
  sell: { value: 'sell', label: 'Sell', minimalLabel: 'S' },
};
// Option types
export const optionTypes = {
  ce: { value: 'CE', label: 'CE' },
  pe: { value: 'PE', label: 'PE' },
};
// Build up configurations
export const buildUpConfigs = {
  shortBuildUp: { label: 'S B', value: 'shortBuildUp', color: '#f44336' },
  shortCovering: { label: 'S C', value: 'shortCovering', color: '#003473' },
  longBuildUp: { label: 'L B', value: 'longBuildUp', color: '#4caf50' },
  longUnWidening: { label: 'L U', value: 'longUnWidening', color: '#ffc107' },
};
export const BROKER_CONFIG = {
  angel_broking: {
    id: 'angel_broking',
    name: 'Angel One',
    headerClass: 'bg-primary',
    href: '/auth/angel_broking',
  },
  fyers: {
    id: 'fyers',
    name: 'Fyers',
    headerClass: '',
    href: '/auth/fyers',
  },
  zebu: {
    id: 'zebu',
    name: 'Zebu',
    headerClass: 'bg-primary',
    href: '/auth/zebu',
  },
  zebu_xts: {
    id: 'zebu_xts',
    name: 'Zebu Xts',
    headerClass: 'bg-primary',
    href: '/auth/zebu_xts',
  },
  philip_capital: {
    id: 'philip_capital',
    name: 'Philip Capital',
    headerClass: 'bg-primary',
  },
  upstox: {
    id: 'upstox',
    name: 'Upstox',
    headerClass: '',
    automatic: false,
    oneclick: false,
    href: '/auth/upstox',
  },
  icici_direct: {
    id: 'icici_direct',
    name: 'ICICIDIRECT',
    headerClass: '',
    is_individual_api: true,
  },
  aliceblue: {
    id: 'aliceblue',
    name: 'Aliceblue',
    headerClass: '',
    href: '/auth/aliceblue',
  },
  smc_global: {
    id: 'smc_global',
    name: 'SMC Global',
    headerClass: '',
    href: '/auth/smc_global',
  },
  stox_kart: {
    id: 'stox_kart',
    name: 'Stox Kart',
    headerClass: '',
    automatic: true,
    oneclick: true,
    href: '/auth/stox_kart',
  },
  fivepaisa: {
    id: 'fivepaisa',
    name: '5paisa',
    headerClass: '',
    href: '/auth/fivepaisa',
  },
  broker_stub: {
    id: 'broker_stub',
    name: 'Broker Stub',
    headerclass: '',
    href: '/auth/broker_stub',
  },
  zerodha: {
    id: 'zerodha',
    name: 'Zerodha',
    headerclass: '',
    automatic: false,
    oneclick: true,
    is_individual_api: true,
  },
  flattrade: {
    id: 'flattrade',
    name: 'Flattrade',
    headerclass: '',
    is_individual_api: true,
  },
  paytm_money: {
    id: 'paytm_money',
    name: 'Paytm Money',
    headerclass: '',
    href: '/auth/paytm_money',
  },
  jainam_duck: {
    id: 'jainam_duck',
    name: 'Duck by Jainam',
    headerClass: '',
    href: '/auth/jainam_duck',
  },
  motilal_oswal: {
    id: 'motilal_oswal',
    name: 'Motilal Oswal',
    headerClass: '',
    href: '/auth/motilal_oswal',
  },
  goodwill: {
    id: 'goodwill',
    name: 'Goodwill',
    headerClass: '',
    href: '/auth/goodwill',
  },
  bigul: {
    id: 'bigul',
    name: 'Bigul',
    headerClass: '',
    is_self_authenticate: true,
  },
  dhan: {
    id: 'dhan',
    name: 'Dhan',
    headerClass: '',
    automatic: false,
    oneclick: false,
    href: '/auth/dhan',
  },
  nuvama: {
    id: 'nuvama',
    name: 'Nuvama',
    headerClass: '',
    href: '/auth/nuvama',
  },
  maitra_commodities: {
    id: 'maitra_commodities',
    name: 'Maitra Wealth',
    headerClass: 'bg-primary',
  },
  tradesmart: {
    id: 'tradesmart',
    name: 'TradeSmart',
    headerClass: 'bg-primary',
  },
  profitmart: {
    id: 'profitmart',
    name: 'ProfitMart',
    headerClass: 'bg-primary',
    supportMail: ' support@profitmart.in ',
  },
  hdfcsky: {
    id: 'hdfcsky',
    name: 'HDFC Sky',
    headerClass: '',
    href: '/auth/hdfcsky',
  },
  enrich_money: {
    id: 'enrich_money',
    name: 'Enrich Money',
    headerClass: 'bg-primary',
    href: '/auth/enrich_money',
  },
  prabhudas: {
    id: 'prabhudas',
    name: 'Prabhudas Lilladher',
    headerClass: 'bg-primary',
  },
  finvasia: {
    id: 'finvasia',
    name: 'Finvasia',
    headerClass: 'bg-primary',
  },
  religare: {
    id: 'religare',
    name: 'Religare',
    headerClass: 'bg-primary',
    supportMail: 'wecare@religareonline.com',
  },
  investright: {
    id: 'investright',
    name: 'HDFC InvestRight',
    headerClass: 'bg-primary',
    href: '/auth/investright',
  },
  optionbuynsell: {
    id: 'optionbuynsell',
    name: 'Option Buy N Sell',
    headerClass: 'bg-primary',
    href: '/auth/optionbuynsell',
  },
  axis_direct: {
    id: 'axis_direct',
    name: 'Axis Direct',
    headerClass: 'bg-primary',
    automatic: true,
    oneclick: true,
    href: '/auth/axis_direct',
  },
  dhani_stocks: {
    id: 'dhani_stocks',
    name: 'Dhani Stocks',
    headerClass: 'bg-primary',
    automatic: true,
    oneclick: true,
    href: '/auth/dhani_stocks',
  },
  iifl_nova: {
    id: 'iifl_nova',
    name: 'IIFL Nova',
    headerClass: 'bg-primary',
    href: '/auth/iifl_nova',
  },
  mncl: {
    id: 'mncl',
    name: 'MNCL',
    headerClass: 'bg-primary',
    href: '/auth/mncl',
  },
  sky_broking: {
    id: 'sky_broking',
    name: 'Sky broking',
    headerClass: '',
    href: '/auth/sky_broking',
  },
  kasat: {
    id: 'kasat',
    name: 'Kasat',
    headerClass: '',
    href: '/auth/kasat',
  },
  almondz: {
    id: 'almondz',
    name: 'Almondz',
    headerClass: 'bg-primary',
    href: '/auth/iifl_nova',
  },
};
// export const BROKERS = _.get(window, 'SA_CONSTANTS.brokerConfigs', BROKER_CONFIG);
export const DOMAINS = {
  ALICE_BLUE: 'aliceblue',
  PAYTM_MONEY: 'paytmmoney',
  DEFAULT_DOMAIN: 'www',
  ICICI_DIRECT: 'icicidirect',
  GOODWILL: 'goodwill',
  BIGUL: 'bigul',
  DHAN: 'dhan',
  NUVAMA: 'nuvama',
  ASTHA_TRADE: 'asthatrade',
  ENRICH_MONEY: 'enrichmoney',
  KOTAK_NEO: 'kotakneo',
};
export const IndexToMonthNames = {
  '01': 'JAN',
  '02': 'FEB',
  '03': 'MAR',
  '04': 'APR',
  '05': 'MAY',
  '06': 'JUN',
  '07': 'JUL',
  '08': 'AUG',
  '09': 'SEP',
  10: 'OCT',
  11: 'NOV',
  12: 'DEC',
};
export const MonthLetterToMonthNames = {
  O: 'OCT',
  N: 'NOV',
  D: 'DEC',
};
export const MonthLetterToIndex = {
  O: 10,
  N: 11,
  D: 12,
};
export const TimeDelay = 60000;
export const SOCKET_SERVER_EVENTS = {
  TICKS: 'ticker:tick',
  ERROR: 'ticker:error',
  STATE: 'ticker:state',
};
export const TICKER_EVENTS = {
  TICKS: 'ticks',
  ERROR: 'error',
  STATE: 'state',
};
export const DEFAULT_STEP_SIZE = 100;
export const MonthNamesToIndex = {
  JAN: '01',
  FEB: '02',
  MAR: '03',
  APR: '04',
  MAY: '05',
  JUN: '06',
  JUL: '07',
  AUG: '08',
  SEP: '09',
  OCT: '10',
  NOV: '11',
  DEC: '12',
};
export const nomenClatures = {
  positive: { label: 'Positive', value: 'positive' },
  negative: { label: 'Negative', value: 'negative' },
  neutral: { label: 'Neutral', value: 'neutral' },
};
export const predictionTypes = {
  percentage: { label: 'Percentage', value: 'percentage' },
  price: { label: 'Price', value: 'price' },
};
export const asyncStoreKeyNames = {
  brokerClientId: 'brokerClientId',
  brokerClientAccessToken: 'brokerClientAccessToken',
  broker: 'broker',
};
export const avoidedInstruments = [
  'CADILAHC',
  'HDFC',
  'SRTRANSFIN',
  'PVR',
  'MINDTREE',
  'AMARAJABAT',
  'TORNTPOWER',
  'ATUL',
  'DUMMYSYM'
];
export const BSE_INDEXES = ['SENSEX', 'BANKEX'];

export const priceTypes = [
  {
    name: 'Close Price',
    value: 'prevDayLtp',
    key: 'changeFromType',
    isShown: true,
    expandLabel: 'All Calculation will be based on Close Price',
  },
  {
    name: 'Open Price',
    value: 'firstOpen',
    key: 'changeFromType',
    isShown: true,
    expandLabel: 'All Calculation will be based on Open Price',
  },
];
export const marginBenefitConfigs = [
  {
    name: 'MB (Yes)',
    value: 'yes',
    key: 'marginBenefit',
    isShown: true,
    expandLabel: 'Place buy order first to see margin benefit',
  },
  {
    name: 'MB (No)',
    value: 'no',
    key: 'marginBenefit',
    isShown: true,
    expandLabel: 'Place order randomly',
  },
];
export const articles = {
  [BROKER_CONFIG.fyers.id]: [
    {
      title: 'Support Articles',
      link: 'https://support.fyers.in/portal/en/kb/platforms-tools/fyers-insta-options',
    },
  ],
};
export const notificationSoundConfigs = [
  {
    name: 'Sound (Yes)',
    value: 'yes',
    key: 'notificationSound',
    isShown: true,
    expandLabel: 'Sound will be played on order execution',
  },
  {
    name: 'Sound (No)',
    value: 'no',
    key: 'notificationSound',
    isShown: true,
    expandLabel: 'Sound will not be played on order execution',
  },
];
export const directPlaceOrderConfigs = [
  {
    name: 'Direct place order',
    value: 'yes',
    key: 'directPlaceOrder',
    isShown: true,
    expandLabel: 'Directly place the order to broker',
  },
  {
    name: 'Seperate Screen to place order',
    value: 'no',
    key: 'directPlaceOrder',
    isShown: true,
    expandLabel: 'We can edit the order and place the order',
  },
];
export const userModeSettings = [
  {
    name: 'buyer',
    value: 'buyer',
    key: 'userMode',
    isShown: true,
    expandLabel: 'Call and Put will be changed based on buyer(colors)',
  },
  {
    name: 'seller',
    value: 'seller',
    key: 'userMode',
    isShown: true,
    expandLabel: 'Call and Put will be changed based on seller(colors)',
  },
];
export const trendingOIComparisonTypes = {
  currentDay: { label: 'Curr Day', value: 'currentDay' },
  previousDay: { label: 'Prev Day', value: 'previousDay' },
};
export const statusConfigs = {
  cancelled: {
    label: 'Cancelled',
    value: 'cancelled',
  },
  executed: {
    label: 'Executed',
    value: 'executed',
  },
  failed: {
    label: 'Failed',
    value: 'failed',
  },
  partiallyExecuted: {
    label: 'Partially Executed',
    value: 'partiallyExecuted',
  },
  placed: {
    label: 'Placed',
    value: 'placed',
  },
  pending: {
    label: 'Pending',
    value: 'pending',
  },
};
export const timeIntervalConfigs = [
  { label: '1 min', value: 1 },
  { label: '3 min', value: 3 },
  { label: '5 min', value: 5 },
  { label: '10 min', value: 10 },
  { label: '15 min', value: 15 },
  { label: '30 min', value: 30 },
  { label: '60 min', value: 60 },
];

export const publisherApiSupportedBrokers = [BROKER_CONFIG.upstox.id];
export const shouldHideapisecret = [];
export const NumberInputAvoidedKeys = ['e', 'E', '+', '-', '.', '/', '*', '='];
export const userRunTypes = {
  live: 'live',
  historical: 'historical',
};
export const placeOrderModes = {
  live: 'live',
  paper: 'paper',
};
export const supportEnabledBrokers = {
  fyers: {
    supportArticles:
      'https://support.fyers.in/portal/en/kb/platforms-tools/fyers-insta-options',
  },
};

export const minimizeOrderPlacerBrokers = [
  'iifl_nova'
];


export const productCode = {
  MIS: 'MIS',
  NRML: 'NRML',
  CNC: 'CNC',
};

export const productType = {
  LIMIT: 'LIMIT',
  MARKET: 'MARKET',
  SL_M: 'STOP',
  SL_L: 'STOPLIMIT',
};