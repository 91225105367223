const getLabelConfigs = () => {
  return {
    placeOrderLabelConfigs: {
      mis: 'INTRADAY',
      nrml: 'MARGIN',
      cnc: 'CNC',
    },
    booksLabelConfigs: {
      mis: 'INTRADAY',
      nrml: 'MARGIN',
      cnc: 'CNC',
    },
    tabsLabelConfigs: {
      orderBook: 'Orders',
      tradeBook: 'Trades',
      positionBook: 'All Positions',
      holdingBook: 'Holdings',
      basketOrders: 'Baskets',
    },
    orderTableHeaders: [
      { label: 'Instrument', key: 'instrument' },
      { label: 'Type', key: 'type' },
      { label: 'Buy/Sell', key: 'buySell' },
      { label: 'Quantity', key: 'extraDetails.qty' },
      { label: 'Limit Price', key: 'limitPrice' },
      { label: 'Stop Price', key: 'stopPrice' },
      { label: 'Price', key: 'extraDetails.tradedPrice' },
      { label: 'LTP', key: 'ltp' },
      { label: 'Status', key: 'status' },
    ],
    tradeTableHeaders: [
      { label: 'Instrument', key: 'instrument' },
      { label: 'Type', key: 'type' },
      { label: 'Buy/Sell', key: 'buySell' },
      { label: 'Qty', key: 'extraDetails.qty' },
      { label: 'Price', key: 'extraDetails.tradedPrice' },
      { label: 'LTP', key: 'ltp' },
      { label: 'Status', key: 'status' },
    ],
    positionTableHeaders: [
      { label: '', key: 'checkbox' },
      { label: 'Instrument', key: 'instrument' },
      { label: 'Type', key: 'extraDetails.type' },
      { label: 'Buy/Sell', key: 'buySell' },
      { label: 'Qty', key: 'qty' },
      { label: 'Buy Avg', key: 'buyAvg' },
      { label: 'Sell Avg', key: 'sellAvg' },
      { label: 'LTP', key: 'ltp' },
      { label: 'P/L', key: 'profitLoss' },
    ],
    holdingTableHeaders: [
      { label: 'Instrument', key: 'instrument' },
      { label: 'Qty', key: 'extraDetails.quantity' },
      { label: 'Buy Avg', key: 'extraDetails.buyAverage' },
      { label: 'LTP', key: 'ltp' },
      { label: 'Buy Value', key: 'extraDetails.buyValue' },
      { label: 'P&L', key: 'profitLoss' },
    ]
  };
};

export { getLabelConfigs };
