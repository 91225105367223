
const orderTypeMap = {
  l: 'LIMIT',
  mkt: 'MARKET',
  'sl-m': 'SL_M',
  sl: 'SL_L',
};
export const formatOrderType = (orderType) => {
  const type = _.trim(orderType.toLowerCase());
  return orderTypeMap[type] || 'MARKET';
};
