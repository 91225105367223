import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { BROKER_CONFIG } from 'habitual-analytics/constants/habitual-configs';
import { fetchBrokerConfigs } from 'habitual-analytics/brokers/index';
import { defaultConfigs } from './defaultConfigs';


export const getApiCredentials = (broker) => {
  const {
    brokerClientAccessToken,
  } = getDynamicAppConfigs().brokerConfigs;
  const { FYERS_APP_ID } = getDynamicAppConfigs().envs;

  switch (broker) {
    case BROKER_CONFIG.fyers.id:
      return {
        apiKey: FYERS_APP_ID,
        accessToken: brokerClientAccessToken,
      };
    case BROKER_CONFIG.angel_broking.id:
      return {
        accessToken: brokerClientAccessToken,
      };
  }
};

export const brokerSpecificTableHeaders = () => {
  //Get labelConfigs from broker and merge with defaultConfigs
  const configs = fetchBrokerConfigs('getLabelConfigs');
  
  return { ...defaultConfigs, ...configs };
};





