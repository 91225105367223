
import { formatOrderType } from './formatHelper';
import { formatTradingSymbol } from './instrumentFormatter';

export const Formatter = (() => {

  return {
    placeOrder: (leg) => {
      return {
        tradingSymbol: formatTradingSymbol(leg?.tradingSymbolObj),
        quantity: _.get(leg, 'qty', 0),
        transactionType: _.get(leg, 'transactionType', '').toUpperCase(),
        productType: _.get(leg, 'pCode', ''),
        validity: _.get(leg, 'ret', ''),
        orderType: {
          type: formatOrderType(_.get(leg, 'prctyp', '')),
          limitPrice: _.toNumber(_.get(leg, 'price', 0)),
          triggerPrice: _.toNumber(_.get(leg, 'trigPrice', 0)),
        },
        referenceOrderId: Math.random().toString().slice(2, 11),
      };
    },

    cancelOrder: (leg) => {
      return [
        {
          //cancel order only need orderNo remain fields are not required
          //verify with the backend team
          tradingSymbol: formatTradingSymbol(leg?.tradingSymbolObj),
          transactionType: _.get(leg, 'orderDetails.type', '').toUpperCase(),
          orderType: {
            type: _.get(leg, 'orderDetails.extraDetails.product', '')
              .split('/')[1],
            limitPrice: _.toNumber(_.get(leg, 'orderDetails.limitPrice', 0)),
            triggerPrice: _.toNumber(_.get(leg, 'orderDetails.stopPrice', 0)),
          },
          quantity: 0,
          filledQuantity: 0,
          cancelledQuantity: 0,
          productType: _.get(leg, 'orderDetails.extraDetails.product', '')
            .split('/')[0],
          validity: 'DAY',
          referenceOrderId: Math.random().toString().slice(2, 11),
          brokerOrderId: _.get(leg, 'orderNo', ''),
          brokerOrderExtras: {},
        },
      ];
    },

    marginCalculation: (leg) => {
      return {
        tradingSymbol: formatTradingSymbol(leg?.tradingSymbolObj),
        quantity: _.toNumber(_.get(leg, 'qty', 0)),
        transactionType: _.get(leg, 'transactionType', '').toUpperCase(),
        productType: _.get(leg, 'pCode', ''),
        orderType: {
          type: formatOrderType(_.get(leg, 'prctyp', '')),
          limitPrice: _.toNumber(_.get(leg, 'price', 0)),
          triggerPrice: _.toNumber(_.get(leg, 'trigPrice', 0)),
        },
      };
    },
    modifyOrder: (leg) => {
      return [{
        tradingSymbol: formatTradingSymbol(leg?.tradingSymbolObj),
        quantity: _.get(leg, 'qty', 0),
        transactionType: _.get(leg, 'transactionType', '').toUpperCase(),
        productType: _.get(leg, 'extraDetails.productType', ''),
        validity: _.get(leg, 'ret', ''),
        orderType: {
          type: formatOrderType(_.get(leg, 'prctyp', '')),
          limitPrice: _.toNumber(_.get(leg, 'price', 0)),
          triggerPrice: _.toNumber(_.get(leg, 'trigPrice', 0)),
        },
        referenceOrderId: Math.random().toString().slice(2, 11),
        filledQuantity: 0,
        cancelledQuantity: 0,
        brokerOrderId: _.get(leg, 'orderNo', ''),
        brokerOrderExtras: {},
      }];
    },
  };
});
