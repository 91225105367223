import MarketUtility from 'habitual-analytics/utils/marketUtility';
import moment from 'moment';
import { getCancellableOrderStatus } from 'habitual-analytics/brokers/utils';
import { PRETTIER_FORMAT_WITH_SECONDS, TIME_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { getFixedData } from 'habitual-analytics/common/formatter/number';
import {
  sanitizeAndParseOrderStatus,
  sanitizeParseOrderType,
  sanitizeProductType,
} from './parseHelper';
import { ParseTradingSymbol } from './instrumentParser';
import { isValidSymbolForInsta } from '../../utils';


export const Parser = (() => {
  return {
    parseOrderBook: (rawItem) => {

      const tradingSymbolObj = ParseTradingSymbol(_.get(rawItem, 'tradingSymbol', ''));
      if (!isValidSymbolForInsta(tradingSymbolObj.getExchangeSegment(), tradingSymbolObj.toString())
        || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
        return null;
      }
      const status = sanitizeAndParseOrderStatus(_.get(rawItem, 'status', ''));
      const lotSize = _.get(rawItem, 'lotSize', 1);
      const tradedQuantity = _.get(rawItem, 'filledQuantity', '') / lotSize;
      const quantity = _.get(rawItem, 'quantity', '') / lotSize;
      const isCancellableOrder = getCancellableOrderStatus(status);
      const productType = sanitizeParseOrderType(_.get(rawItem, 'orderType', ''));
      const productCode = sanitizeProductType(_.get(rawItem, 'productType', ''), tradingSymbolObj);

      return {
        tradingSymbolObj,
        type: _.get(rawItem, 'transactionType', '').toLowerCase(),
        time: moment(_.get(rawItem, 'orderTimeStamp', '').split(' ')[1], TIME_FORMAT)
          ?.format(PRETTIER_FORMAT_WITH_SECONDS),
        status: status,
        isCancellableOrder,
        stopPrice: _.get(rawItem, 'triggerPrice', ''),
        limitPrice: _.get(rawItem, 'limitPrice', ''),
        failedReason: _.get(rawItem, 'message', ''),
        extraDetails: {
          product: `${productType} / ${productCode}`,
          qty: `${tradedQuantity} / ${quantity}`,
          orderNo: _.get(rawItem, 'brokerOrderId', ''),
          tradedPrice: _.get(rawItem, 'tradedPrice', 0),
          defaultProductType: _.get(rawItem, 'orderType', ''),
          defaultProductCode: _.get(rawItem, 'productType', ''),
        },
      };
    },
    parseTradeBook: (rawItem) => {
      const tradingSymbolObj = ParseTradingSymbol(_.get(rawItem, 'tradingSymbol', ''));
      if (!isValidSymbolForInsta(tradingSymbolObj.getExchangeSegment(), tradingSymbolObj.toString()) ||
        !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
        return null;
      }
      const productCode = sanitizeProductType(_.get(rawItem, 'productType', ''), tradingSymbolObj);
      const productType = sanitizeParseOrderType(_.get(rawItem, 'orderType', ''));
      const lotSize = _.get(rawItem, 'lotSize', 1);
      const quantity = _.get(rawItem, 'quantity', 0) / lotSize;

      return {
        tradingSymbolObj,
        time: moment(_.get(rawItem, 'orderTimeStamp', '').split(' ')[1], TIME_FORMAT)
          ?.format(PRETTIER_FORMAT_WITH_SECONDS),
        type: _.get(rawItem, 'transactionType', '').toLowerCase(),
        status: sanitizeAndParseOrderStatus(_.get(rawItem, 'status', '')),
        quantity,
        extraDetails: {
          product: `${productCode} / ${productType}`,
          qty: `${quantity} / ${quantity}`,
          tradedPrice: _.get(rawItem, 'tradedPrice', 0),
        },
      };
    },
    parsePositionBook: (rawItem) => {
      const tradingSymbolObj = ParseTradingSymbol(_.get(rawItem, 'tradingSymbol', ''));

      if (!isValidSymbolForInsta(tradingSymbolObj.getExchangeSegment(), tradingSymbolObj.toString())
        || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
        return null;
      }
      const qty = _.get(rawItem, 'quantity', 0);
      return {
        tradingSymbolObj,
        type: _.get(rawItem, 'transactionType', '').toLowerCase(),
        qty,
        buyAvg: Number(getFixedData(_.get(rawItem, 'buyAveragePrice', 0))),
        sellAvg: Number(getFixedData(_.get(rawItem, 'sellAveragePrice', 0))),
        ltp: _.get(rawItem, 'ltp', 0),
        buyQuantity: _.get(rawItem, 'buyQuantity', 0),
        sellQuantity: _.get(rawItem, 'sellQuantity', 0),
        avgPrice: _.get(rawItem, 'avgPrice', 0),
        profitLoss: _.get(rawItem, 'profitLoss', 0),
        realizedProfitLoss: _.get(rawItem, 'realizedProfitLoss', 0),
        extraDetails: {
          product: sanitizeProductType(_.get(rawItem, 'productType', ''), tradingSymbolObj),
          isOpenPosition: _.get(rawItem, 'isOpenPosition', false),
          type: qty !== 0 ? _.get(rawItem, 'transactionType', '').toLowerCase() : '',
          defaultProductCode: sanitizeProductType(
            _.get(rawItem, 'productType', ''), tradingSymbolObj),
        },
      };
    },
    parseHoldingBook: (rawItem) => {
      const tradingSymbolObj = ParseTradingSymbol(_.get(rawItem, 'tradingSymbol', ''));
      if (!isValidSymbolForInsta(tradingSymbolObj.getExchangeSegment(), tradingSymbolObj.toString())
        || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
        return null;
      }

      return {
        tradingSymbolObj: tradingSymbolObj,
        ltp: _.get(rawItem, 'ltp', 0),
        profitLoss: _.get(rawItem, 'profitLoss', 0),
        extraDetails: {
          quantity: _.get(rawItem, 'quantity', 0),
          buyAverage: _.get(rawItem, 'buyAveragePrice', 0),
          sellAverage: _.get(rawItem, 'sellAveragePrice', 0),
          buyValue: _.get(rawItem, 'buyValue', 0),
          averagePrice: _.get(rawItem, 'averagePrice', 0),
          netChg: _.get(rawItem, 'netChange', ''),

        },
      };
    },
    // once verify the below method with the existing postback tracker
    parsePostBackOrder: (rawItem) => {
      const tradingSymbolObj = ParseTradingSymbol(_.get(rawItem, 'tradingSymbol', {}));

      return {
        tradingSymbolObj,
        status: sanitizeAndParseOrderStatus(_.get(rawItem, 'status', '')),
        failedReason: _.get(rawItem, 'message', ''),
        extraDetails: {
          orderNo: _.get(rawItem, 'brokerOrderId', ''),
        },
      };
    },
    parseTransactionResponse: (transactionResponse) => {
      return _.values(transactionResponse).map((item) => {
        if (!item.success) {
          // If token or api_key are invalid then it will return the error object
          return { success: false, message: item.error?.reason || item.error?.message };
        }
        return { success: true, orderId: item.brokerOrderId };
      });
    }
  };
});

